import type { IPricesStateManager } from '../../state-managers';
import type { KioskMenuItem, KioskModifierGroup } from '../../models';

type KioskModifierGroupWithoutComputedProperties = Omit<
  KioskModifierGroup,
  'hasOneRequiredVisibleModifier' | 'hasOneFlattenModifier'
>;

export const hasOneRequiredVisibleModifier = (
  group: KioskModifierGroupWithoutComputedProperties
) => {
  return (
    group.modifiers.filter((modifier) => !modifier.isInvisible).length === 1 && group.selectMin > 0
  );
};

export const getPriceOfFirstVisibleModifier = (
  group: KioskModifierGroup,
  item: KioskMenuItem,
  priceStateManager: IPricesStateManager
): number | undefined => {
  const visibleModifiers = group.modifiers.filter((modifier) => !modifier.isInvisible);
  if (visibleModifiers.length === 0) {
    return undefined;
  }

  return priceStateManager.getPriceForModifierInItem(
    item.menuItemId,
    visibleModifiers[0].modifierId
  );
};

export const hasOneFlattenModifier = (
  group: KioskModifierGroup,
  firstVisibleModifierPrice: number | undefined
) => {
  const visibleModifiers = group.modifiers.filter((modifier) => !modifier.isInvisible);
  return (
    visibleModifiers.length === 1 && // modifier should be visible
    group.selectMin === 0 && // modifier should be optional
    firstVisibleModifierPrice === 0 &&
    // priceStateManager.getPriceForModifierInItem(item.menuItemId, visibleModifiers[0].modifierId) ===
    visibleModifiers[0].menuItem.modifierGroups.length > 0 &&
    visibleModifiers[0].menuItem.modifierGroups.some(
      (childModifierGroup) => childModifierGroup.name.toLowerCase() === group.name.toLowerCase() // modifier child should have different name
    )
  );
};
