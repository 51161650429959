import * as Sentry from '@sentry/svelte';

export type LogCategory =
  | 'AmazonOne'
  | 'CloudRequest'
  | 'AgentRequest'
  | 'Printer'
  | 'Payment'
  | 'GEM'
  | 'Dev'
  | 'OrderProcess'
  | 'PaymentProcess'
  | 'PaymentDeviceProcess'
  | 'LoyaltyProcess'
  | 'VerifoneProcess'
  | 'VerifoneProxyPrimaryProcess'
  | 'VerifoneProxySecondaryProcess';

export const Logger = {
  log(category: LogCategory, ...args: unknown[]): void {
    console.log.apply(null, [`[${category}]`, ...args]);
  },
  logError(error: unknown, consoleMessage: string): { sentryTraceId: string } {
    const traceId = Sentry.captureException(error);
    console.log.apply(null, [
      `[Error]`,
      consoleMessage,
      (error as Error).message,
      (error as Error).stack,
    ]);
    return { sentryTraceId: traceId };
  },
};
