import {
  type MenuItem,
  MenuItemDisplayFlow,
  type MenuItemPizzaSpecificAttributes,
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType,
} from '@grubbrr/nextgen-kiosk-client';
import type { KioskTimeSchedule } from './kiosk_time_schedule';
import type { KioskEntityMedia } from './kiosk_entity_media';
import { isValidOneOf } from '../data-management/utils';
import type { KioskModifierCode } from './kiosk_modifier_code';
import type { Modifier } from '@grubbrr/nextgen-kiosk-client';
import type { ModifierGroup } from '@grubbrr/nextgen-kiosk-client';
import { hasOneRequiredVisibleModifier } from '../states/menu/modifier_group_utils';

export type KioskModifierGroup = Omit<ModifierGroup, 'selectMax' | 'modifierIds'> & {
  modifiers: KioskModifier[];
  selectMax: number;
  isInvisible: boolean;
  hasOneRequiredVisibleModifier: boolean;
};

export type KioskModifier = Omit<Modifier, 'modifierCodes' | 'price'> & {
  menuItem: KioskMenuItem;
  isIncludedInItemPrice: boolean;
  modifierCodes: KioskModifierCode[];
  defaultQuantity: number;
  isDefault: boolean;
  isActive: boolean;
};

export type KioskMenuItem = Omit<
  MenuItem,
  | 'media'
  | 'modifierOverrides'
  | 'modifierGroupIds'
  | 'price'
  | 'isModifierOnly'
  | 'menuItemModifierGroup'
  | 'selectedImage'
> & {
  modifierGroups: KioskModifierGroup[];
  cartItemId: string;
  timeSchedule: KioskTimeSchedule;
  areAllModifierGroupsInvisible: boolean;
};

export type KioskMenuItemMedia = KioskEntityMedia;

export type KioskMenuItemPizzaSpecificAttributes = MenuItemPizzaSpecificAttributes & {
  modifier_group_id_coverage: string[];
  modifier_group_id_toppings: string[];
  modifier_group_id_size: string[];
  modifier_group_id_crust: string[];
};

const getAllModifierGroupTypes = (
  modifierGroup: KioskModifierGroup[],
  // eslint-disable-next-line camelcase
  accum: Map<ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType, string[]>
) => {
  for (const group of modifierGroup) {
    const type = getModifierGroupPizzaType(group);
    if (!type) {
      continue;
    }

    if (accum.get(type)) accum.get(type)!.push(group.modifierGroupId);
    else accum.set(type, [group.modifierGroupId]);

    for (const modifier of group.modifiers) {
      getAllModifierGroupTypes(modifier.menuItem.modifierGroups, accum);
    }
  }

  return accum;
};

export const getModifierGroupPizzaType = (
  group: KioskModifierGroup
  // eslint-disable-next-line camelcase
): ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType | null => {
  if (
    !(
      group.domainSpecificAttributes &&
      isValidOneOf(group.domainSpecificAttributes.attributes) &&
      group.domainSpecificAttributes.attributes.oneofKind === 'pizzaAttributes'
    )
  ) {
    return null;
  }

  const base = group.domainSpecificAttributes.attributes.pizzaAttributes;
  return base.type;
};

export const getPizzaAttributes = (
  item: KioskMenuItem
): KioskMenuItemPizzaSpecificAttributes | null => {
  if (
    !(
      item.domainSpecificAttributes &&
      isValidOneOf(item.domainSpecificAttributes.attributes) &&
      item.domainSpecificAttributes.attributes.oneofKind === 'pizzaAttributes'
    )
  ) {
    return null;
  }

  const groupsByType = getAllModifierGroupTypes(item.modifierGroups, new Map());

  const base = item.domainSpecificAttributes.attributes.pizzaAttributes;
  return {
    ...base,
    // eslint-disable-next-line camelcase
    modifier_group_id_coverage:
      groupsByType.get(
        // eslint-disable-next-line camelcase
        ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Coverage
      ) ?? [],
    // eslint-disable-next-line camelcase
    modifier_group_id_toppings:
      groupsByType.get(
        // eslint-disable-next-line camelcase
        ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Topping
      ) ?? [],
    // eslint-disable-next-line camelcase
    modifier_group_id_size:
      // eslint-disable-next-line camelcase
      groupsByType.get(ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Size) ??
      [],
    // eslint-disable-next-line camelcase
    modifier_group_id_crust:
      // eslint-disable-next-line camelcase
      groupsByType.get(ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Crust) ??
      [],
  };
};

export const areAllModifierGroupsInVisible = (item: KioskMenuItem): boolean => {
  if (isPizzaItem(item)) return false;
  return item.modifierGroups.every((modGroup) => {
    if (modGroup.isInvisible) {
      const selectedModifier = modGroup.modifiers.find((modifier) => modifier.isDefault);
      if (selectedModifier) {
        return areAllModifierGroupsInVisible(selectedModifier.menuItem);
      } else {
        return true;
      }
    } else {
      const areAllInvisible = modGroup.modifiers.every((modifiers) => modifiers.isInvisible);
      // If all are not invisible and there is one required visible modifier then take that visible modifier and go for nested checks
      if (!areAllInvisible && hasOneRequiredVisibleModifier(modGroup)) {
        const singleVisibleModifier = modGroup.modifiers.find((modifier) => !modifier.isInvisible);
        if (singleVisibleModifier) {
          return areAllModifierGroupsInVisible(singleVisibleModifier.menuItem);
        } else {
          return areAllInvisible;
        }
      } else {
        return areAllInvisible;
      }
    }
  });
};

export const isPizzaItem = (item: KioskMenuItem | undefined) => {
  // If display mode is pizza, has one modifier group which crust
  return (
    item &&
    item.flow === MenuItemDisplayFlow.Pizza &&
    item.modifierGroups.length === 1 &&
    isModifierPizzaCrust(item.modifierGroups[0])
  );
};

export const isValidPizzaAttribute = (group: KioskModifierGroup) => {
  return (
    group.domainSpecificAttributes &&
    isValidOneOf(group.domainSpecificAttributes.attributes) &&
    group.domainSpecificAttributes.attributes.oneofKind === 'pizzaAttributes'
  );
};

export const isModifierPizzaCrust = (group: KioskModifierGroup) => {
  if (isValidPizzaAttribute(group)) {
    const pizzaAttribute = getPizzaAttributeForModifierGroup(group);
    return (
      pizzaAttribute?.type ===
      // eslint-disable-next-line camelcase
      ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Crust
    );
  }
  return false;
};

export const getPizzaAttributeForModifierGroup = (group: KioskModifierGroup) => {
  if (
    isValidOneOf(group.domainSpecificAttributes?.attributes) &&
    group.domainSpecificAttributes?.attributes.oneofKind === 'pizzaAttributes'
  ) {
    return group.domainSpecificAttributes.attributes.pizzaAttributes;
  }
};
