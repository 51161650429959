<script lang="ts">
  export let cssWidth = 'w-full';
  export let cssHeight = 'h-full';
</script>

<div class="relative {cssWidth} {cssHeight}">
  <div class="pb-full pointer-events-none" />
  <div class="absolute w-full h-full top-0 left-0">
    <slot />
  </div>
</div>
