<script lang="ts">
  import type { DownloadedImageAsset } from 'business-states/src/lib/models';
  import MediaInternal from './MediaInternal.svelte';
  import MediaContainer from './MediaContainer.svelte';

  export let src: string | DownloadedImageAsset;
  export let alt = '';
  export let style = '';
  export let id = '';
  export let placeholder = '';
  export let cover = false;
  export let cssClass = '';
  export let cssWidth = 'w-full';
  export let cssHeight = 'h-full';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export let onLoad: (_video: CustomEvent<HTMLVideoElement>) => void = (_video: CustomEvent<HTMLVideoElement>) => null;
</script>

<MediaContainer {cssWidth} {cssHeight}>
  <MediaInternal
    {src}
    {alt}
    {style}
    {id}
    {placeholder}
    {cover}
    {cssClass}
    on:load={onLoad}
  />
</MediaContainer>
