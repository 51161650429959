<script lang="ts">
  export let placeholder = '';
</script>

<div class="h-full w-full flex-center b-2 b-current text-shadow-md text-center shadow-md">
  <div class="overflow-hidden">
    <div
      aria-hidden="true"
      class="font-custom-semibold-sm line-clamp-2 break-words"
    >
      {placeholder}
    </div>
    <div class="border-t-2 w-9/10 border-primary mx-auto" />
  </div>
</div>
